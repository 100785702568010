.app-skeleton {
    margin-top: 100px !important;
}

.fetching-info {
    margin-top: 100px;
    height: 150px;
    display: flex;
    justify-content: center;
}

.fetching-info p {
    padding-top: 50px;
    text-align: center; 
}

.project-url a {
    max-width: 210px !important;
}

.project-url a span{
    margin-top: -2px !important;
}

@media only screen and (max-width: 767px) {
    .app-skeleton {
        margin-top: 70px !important;
    }

    .project-url a span{
        margin-top: 3px !important;
    }
}